<template>
    <img class="img-responsive" style="max-width:200px" :src="getPathImage" />
</template>

<script>
export default {
    props: ["data", "index", "column"],
     computed:{
        getPathImage(){
            let result = this.data.image_path;
            if(this.data.image_path.indexOf(process.env.VUE_APP_API_URL)!==0){
                let slash = this.data.image_path.indexOf('/')===0? '':'/';
                result = this.data.image_path.indexOf('http')
                    === 0 ? this.data.image_path : process.env.VUE_APP_API_URL + "/gallery/api/image/alt" + slash + this.data.image_path;
            }
            return result;
        }
    },
}
</script>